import { useState } from 'react'
import styled from '@emotion/styled'
import { Box } from '@chakra-ui/react'
import useInView from 'react-cool-inview'
import { CloseWindow } from '../svg/CloseWindow'

import ReactPlayer from 'react-player/lazy'

const Wrapper = styled(Box)`
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  .react-player-fixed {
    position: fixed;
    z-index: 100;
    bottom: 30px;
    right: 100px;
  }

  .react-player-absolute {
    position: absolute;
    top: 0;
    left: 0;
  }
`

export interface VideoPreviewProps {
  file: any
  thumbnail?: string
}

export const VideoPreview = ({ file, thumbnail }: VideoPreviewProps) => {
  const [progress, setProgress] = useState(0)
  const [pipIsEnable, setPipEnable] = useState(false)
  const [shouldPlay, setShouldPlay] = useState(true)
  const [wasClosed, setWasClosed] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  const { observe: observeTop, inView: inViewTop } = useInView({
    threshold: 0.25,
    onChange: ({ observe }) => {
      observe()
    },
  })

  const { observe: observeBottom, inView: inViewBottom } = useInView({
    threshold: 0.25,
    onChange: ({ observe }) => {
      observe()
    },
  })

  const shouldFixed =
    progress > 0 &&
    !inViewTop &&
    !inViewBottom &&
    !pipIsEnable &&
    shouldPlay &&
    !wasClosed

  return (
    <Box width="100%" maxWidth="1080px">
      <Wrapper>
        <div ref={observeTop} />
        <div
          className={
            shouldFixed ? 'react-player-fixed' : 'react-player-absolute'
          }
          style={{
            width: shouldFixed ? '400px' : '100%',
            height: shouldFixed ? '220px' : '100%',
          }}
        >
          {shouldFixed && (
            <Box
              height="28px"
              width="28px"
              backgroundColor="var(--colors-hint-close-button)"
              borderRadius="50%"
              position="relative"
              top="5px"
              left="-10px"
            >
              <CloseWindow
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '2px',
                  right: '2px',
                }}
                onClick={() => {
                  setShouldPlay(false)
                  setWasClosed(true)
                  setIsPlaying(false)
                }}
                fill="var(--colors-buttons-primary-text)"
              />
            </Box>
          )}
          <ReactPlayer
            url={file}
            light={thumbnail}
            config={{
              file: {
                forceVideo: true,
              },
            }}
            playing={isPlaying}
            width={shouldFixed ? '400px' : '100%'}
            height={shouldFixed ? '220px' : '100%'}
            controls={true}
            progressInterval={progress}
            onPlay={() => {
              setShouldPlay(true)
              setWasClosed(false)
              setIsPlaying(true)
            }}
            onProgress={(prog) => setProgress(prog.playedSeconds)}
            onEnablePIP={() => {
              setPipEnable(true)
            }}
            onDisablePIP={() => {
              setShouldPlay(false)
              setPipEnable(false)
            }}
            preload="metadata"
          />
        </div>
        <div ref={observeBottom} />
      </Wrapper>
    </Box>
  )
}

VideoPreview.displayName = 'VideoPreview'
