import styled from '@emotion/styled'
import { useRef } from 'react'
import { Box } from '@chakra-ui/react'
import { MemoFDE } from '../MemoFDE'

import ReactPlayer from 'react-player/lazy'

const Wrapper = styled(Box)`
  width: 100%;
  position: relative;

  .react-player-fixed {
    position: fixed;
    z-index: 100;
    bottom: 30px;
    right: 100px;
  }

  .react-player-absolute {
    position: absolute;
    top: 0;
    left: 0;
  }
`

export interface VideoAutoPlayPreviewProps {
  file: any
  first: boolean
  start?: number
  playing: boolean
}

export const VideoAutoPlayPreview = MemoFDE(
  ({ file, playing }: VideoAutoPlayPreviewProps) => {
    const muted = false
    const volume = 0.7

    const playerRef = useRef<any>(null)

    return (
      <Box maxWidth={'400px'}>
        <Wrapper>
          <ReactPlayer
            ref={playerRef}
            url={file}
            playing={playing}
            muted={muted}
            volume={volume}
            controls={true}
            config={{
              file: {
                forceVideo: true,
              },
            }}
            width={'400px'}
            height={'220px'}
            preload="metadata"
          />
        </Wrapper>
      </Box>
    )
  },
)

VideoAutoPlayPreview.displayName = 'VideoAutoPlayPreview'
