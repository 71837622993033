import {
  Button,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Text,
  Box,
} from '@chakra-ui/react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { ErrorMessage } from '../ErrorMessage'
import { convertTimeToSeconds, formatTime } from '../Document/MediaProgressBar'
import { PauseIcon } from '../svg/PauseIcon'
import { PlayIcon } from '../svg/PlayIcon'

interface AudioPlayerProps {
  audioUrl?: string
  startTime?: string
  endTime?: string
  width?: number
  height?: number
}

export const AudioPlayer = ({
  audioUrl,
  startTime,
  endTime,
  width,
  height,
}: AudioPlayerProps) => {
  const playerRef = useRef<ReactPlayer>(null)

  const [isLoading, setLoading] = useState<boolean>(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [played, setPlayed] = useState(0)
  const [error, setError] = useState<Error | null>(null)

  const clipStartTime = useMemo(() => {
    return startTime ? convertTimeToSeconds(startTime) : 0
  }, [startTime])

  const clipEndTime = useMemo(() => {
    return endTime ? convertTimeToSeconds(endTime) : 0
  }, [endTime])

  const clipDuration = useMemo(() => {
    return endTime && startTime ? clipEndTime - clipStartTime : null
  }, [endTime, startTime, clipEndTime, clipStartTime])

  useEffect(() => {
    if (audioUrl) {
      setLoading(false)
    }
  }, [audioUrl])

  const handleProgress = useCallback(
    (state: { played: number; playedSeconds: number }) => {
      if (clipEndTime > 0) {
        if (state.playedSeconds >= clipEndTime) {
          setIsPlaying(false)
          playerRef.current?.seekTo(clipStartTime, 'seconds')
          setPlayed(0)
        } else if (state.playedSeconds >= clipStartTime) {
          setPlayed(state.playedSeconds - clipStartTime)
        }
      } else {
        setPlayed(state.playedSeconds)
      }
    },
    [clipEndTime, clipStartTime],
  )

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying)
    if (
      !isPlaying &&
      played >= clipEndTime - clipStartTime &&
      clipEndTime > 0 &&
      clipStartTime > 0
    ) {
      setPlayed(0)
      playerRef.current?.seekTo(clipStartTime, 'seconds')
    }
  }

  const handleSeek = (value: number) => {
    const seekValue = Math.min(
      Math.max(0, value),
      clipEndTime - clipStartTime || Infinity,
    )
    setPlayed(seekValue)
    playerRef.current?.seekTo(seekValue + clipStartTime, 'seconds')
  }

  const handleError = (e: any) => {
    console.error('Audio player error', e)
    setError(e as Error)
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  return (
    <Box position="relative" width={width} height={height}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ReactPlayer
            ref={playerRef}
            url={audioUrl}
            controls={false} // We'll use custom controls
            width="100%"
            height="100%"
            playing={isPlaying}
            onProgress={handleProgress}
            onError={handleError}
            config={{ file: { forceAudio: true } }} // Force audio
          />
          <Flex
            width="100%"
            alignItems="center"
            p={2}
            backgroundColor="#484848"
            position="absolute"
            top={0}
            borderRadius={16}
            className="controlsWrapper"
          >
            <Button
              onClick={handlePlayPause}
              mr={2}
              background="transparent"
              padding={0}
              _hover={{ background: 'transparent' }}
            >
              {isPlaying ? (
                <PauseIcon height={24} width={24} fill="#ffffff" />
              ) : (
                <PlayIcon height={24} width={24} fill="#ffffff" />
              )}
            </Button>
            <Slider
              aria-label="audio-progress"
              min={0}
              max={clipDuration ?? 0}
              value={played}
              onChange={handleSeek}
              flex="1"
              mr={2}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Text whiteSpace="nowrap" textColor="#ffffff">
              {clipDuration
                ? `${formatTime(played)} / ${formatTime(clipDuration)}`
                : formatTime(played)}
            </Text>
          </Flex>
        </>
      )}
    </Box>
  )
}
