import {
  Box,
  Button,
  Flex,
  Image,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Text,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { convertTimeToSeconds, formatTime } from '../Document/MediaProgressBar'
import { ErrorMessage } from '../ErrorMessage'
import { PauseIcon } from '../svg/PauseIcon'
import { PlayIcon } from '../svg/PlayIcon'

interface VideoPlayerProps {
  videoUrl?: string
  thumbnailUrl?: string
  startTime?: string
  endTime?: string
  width?: number
  height?: number
  videoName?: string
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoUrl,
  thumbnailUrl,
  startTime,
  endTime,
  width,
  height,
  videoName,
}) => {
  const playerRef = useRef<ReactPlayer>(null)

  const [isLoading, setLoading] = useState<boolean>(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [played, setPlayed] = useState(0)
  const [hasPlayed, setHasPlayed] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const clipStartTime = useMemo(() => {
    return startTime ? convertTimeToSeconds(startTime) : 0
  }, [startTime])

  const clipEndTime = useMemo(() => {
    return endTime ? convertTimeToSeconds(endTime) : 0
  }, [endTime])

  const clipDuration = useMemo(() => {
    return endTime && startTime ? clipEndTime - clipStartTime : null
  }, [endTime, startTime, clipEndTime, clipStartTime])

  useEffect(() => {
    if (videoUrl) {
      setLoading(false)
    }
  }, [videoUrl])

  const handleProgress = useCallback(
    (state: { played: number; playedSeconds: number }) => {
      if (clipEndTime > 0) {
        if (state.playedSeconds >= clipEndTime) {
          setIsPlaying(false)
          playerRef.current?.seekTo(clipStartTime, 'seconds')
          setPlayed(0)
        } else if (state.playedSeconds >= clipStartTime) {
          setPlayed(state.playedSeconds - clipStartTime)
        }
      } else {
        setPlayed(state.playedSeconds)
      }
    },
    [clipEndTime, clipStartTime],
  )

  const handlePlayPause = () => {
    setHasPlayed(true)
    setIsPlaying(!isPlaying)

    if (
      !isPlaying &&
      played >= clipEndTime - clipStartTime &&
      clipEndTime - clipStartTime > 0
    ) {
      playerRef.current?.seekTo(clipStartTime, 'seconds')
      setPlayed(0)
    }
  }

  const handleSeek = (value: number) => {
    const seekValue = Math.min(
      Math.max(0, value),
      clipEndTime - clipStartTime || Infinity, // Handle cases where clipEndTime is not defined
    )
    setPlayed(seekValue)
    playerRef.current?.seekTo(seekValue + clipStartTime, 'seconds')
  }

  const handleError = (e: any) => {
    console.error('Video player error', e)
    setError(e as Error)
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  return (
    <Box
      position="relative"
      width={width}
      height={height}
      _hover={{
        '.controlsWrapper': { opacity: 1 }, // Apply hover effect to controls
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ReactPlayer
            ref={playerRef}
            url={videoUrl}
            controls={false}
            light={
              thumbnailUrl && !hasPlayed ? (
                <Image
                  src={thumbnailUrl}
                  alt={videoName}
                  maxW="100%"
                  maxH="100%"
                  objectFit="contain"
                />
              ) : undefined
            }
            width={width}
            height={height}
            onProgress={handleProgress}
            playsinline
            playIcon={<></>}
            playing={isPlaying}
            onClickPreview={() => setIsPlaying(true)} // Click to Start Playing on Thumbnail
            onEnded={() => setIsPlaying(false)}
            onError={handleError}
            config={{
              file: {
                forceVideo: true,
              },
            }}
          />
          <Flex
            width="100%"
            alignItems="center"
            position="absolute"
            bottom={0}
            p={2}
            backgroundColor="#484848"
            borderRadius={16}
            opacity={0} // Initially hidden
            transition="opacity 0.2s linear" // Smooth transition
            className="controlsWrapper"
          >
            <Button
              onClick={handlePlayPause}
              mr={2}
              background="transparent"
              padding={0}
              _hover={{ background: 'transparent' }}
            >
              {isPlaying ? (
                <PauseIcon height={24} width={24} fill="#ffffff" />
              ) : (
                <PlayIcon height={24} width={24} fill="#ffffff" />
              )}
            </Button>
            <Slider
              aria-label="video-progress"
              min={0}
              max={clipDuration ?? 0} // Ensures slider works correctly if no clipEndTime
              value={played}
              onChange={handleSeek}
              flex="1"
              mr={2}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Text whiteSpace="nowrap" textColor="#ffffff">
              {clipDuration
                ? `${formatTime(played)} / ${formatTime(clipDuration)}`
                : formatTime(played)}
            </Text>
          </Flex>
        </>
      )}
    </Box>
  )
}
