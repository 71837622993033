interface DimensionProps {
  height?: number
  width?: number
}

const dimensionProps = (props: DimensionProps) => ({
  height: `${props.height}px`,
  width: `${props.width}px`,
})

interface SpacingProps {
  margin?: number | string
  marginBottom?: number | string
  marginLeft?: number | string
  marginRight?: number | string
  marginTop?: number | string
  padding?: number | string
  paddingBottom?: number | string
  paddingLeft?: number | string
  paddingRight?: number | string
  paddingTop?: number | string
}

const spacingProps = (props: SpacingProps) => ({
  margin: props.margin,
  marginBottom: props.marginBottom,
  marginLeft: props.marginLeft,
  marginRight: props.marginRight,
  marginTop: props.marginTop,
  padding: `${props.padding}px`,
  paddingBottom: props.paddingBottom,
  paddingLeft: props.paddingLeft,
  paddingRight: props.paddingRight,
  paddingTop: props.paddingTop,
})

interface BorderProps {
  borderColor?: string
  borderRadius?: number
  borderStyle?: string
  borderWidth?: number
}

const borderProps = (props: BorderProps) => ({
  border: '1px solid transparent',
  borderColor: props.borderColor,
  borderRadius: `${props.borderRadius}px`,
  borderStyle: props.borderStyle,
  borderWidth: `${props.borderWidth}px`,
})

interface LayoutProps {
  flexDirection?: any
  flexWrap?: any
  justifyContent?: any
}

const layoutProps = (props: LayoutProps) => ({
  flexDirection: props.flexDirection,
  flexWrap: props.flexWrap,
  justifyContent: props.justifyContent,
})
export interface ContainerProps
  extends LayoutProps,
    DimensionProps,
    SpacingProps,
    BorderProps {
  children?: React.ReactNode
  backgroundColor?: string
  flexDirection?: 'row' | 'column'
  style?: React.CSSProperties // Include style for additional styles
}

export const getContainerStyles = (
  props: ContainerProps,
): React.CSSProperties => ({
  display: 'flex',
  backgroundColor: props.backgroundColor,
  ...layoutProps(props),
  ...dimensionProps(props),
  ...spacingProps(props),
  ...borderProps(props),
  ...props.style,
})

export interface TextStyleProps extends DimensionProps, SpacingProps {
  text?: string
  backgroundColor?: string
  style?: React.CSSProperties
}

// function for generating Text styles
export const getTextStyles = (props: TextStyleProps): React.CSSProperties => ({
  display: 'flex',
  backgroundColor: props.backgroundColor,
  ...dimensionProps(props),
  ...spacingProps(props),
  ...props.style,
})

export interface LineProps extends DimensionProps, SpacingProps {
  backgroundColor?: string
  style?: React.CSSProperties
}

// Function to generate line styles
export const getLineStyles = (props: LineProps): React.CSSProperties => ({
  backgroundColor: props.backgroundColor,
  ...dimensionProps(props),
  ...spacingProps(props),
  ...props.style,
})

export interface PageProps extends DimensionProps, SpacingProps {
  children?: React.ReactNode
  flexDirection?: 'row' | 'column'
  backgroundColor?: string
  style?: React.CSSProperties
}

export const getPageStyles = (props: PageProps): React.CSSProperties => ({
  display: 'flex',
  alignItems: 'start',
  backgroundColor: props.backgroundColor ?? 'white',
  ...layoutProps(props),
  ...spacingProps(props),
  ...props.style,
})

export interface FileProps extends DimensionProps, SpacingProps {
  documentId: string
  documentName: string
  documentDescription?: string
  documentMimetype: string
  documentThumbnailUrl?: string
  documentDownloadUrl?: string
  style?: React.CSSProperties
}

type ClipProps = {
  endTime?: string
  startTime?: string
}

export type VideoProps = FileProps & ClipProps

export const getVideoStyles = (props: VideoProps): React.CSSProperties => ({
  ...dimensionProps(props),
  ...spacingProps(props),
  ...props.style,
})

export type AudioProps = FileProps & ClipProps

export const getAudioStyles = (props: AudioProps): React.CSSProperties => ({
  ...dimensionProps(props),
  ...spacingProps(props),
  ...props.style,
})

export type ImageProps = FileProps

export const getImageStyles = (props: ImageProps): React.CSSProperties => ({
  ...dimensionProps(props),
  ...spacingProps(props),
  ...props.style,
})

export const getFileStyles = (props: FileProps): React.CSSProperties => ({
  ...dimensionProps(props),
  ...spacingProps(props),
  ...props.style,
})
